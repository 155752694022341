import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field margin-top-10",staticStyle:{"display":"flex","flex-direction":"column"},style:(("min-height:" + (_vm.$store.state.isMobile?'200px':'400px')))},[(_vm.menuItem.styles.show_icon)?_c('div',{staticClass:"control",staticStyle:{"position":"relative","display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"width":"120px","display":"block","margin-bottom":"0"}},[_vm._v("Icon")]),_c(VSelect,{style:(("max-width: " + (_vm.$store.state.isMobile?'auto':'150px'))),attrs:{"outlined":"","solo":"","flat":"","hide-details":"","dense":"","menu-props":{ contentClass: 'my-awesome-list' },"items":_vm.icons.data.map(function (item) { return item.url; }),"value":_vm.menuItem.icon[_vm.landing.current_lang]},on:{"click":function($event){_vm.showIcons = !_vm.showIcons}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"icon",attrs:{"src":item}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"icon",attrs:{"src":item}})]}}],null,false,2844455548),model:{value:(_vm.menuItem.icon[_vm.landing.current_lang]),callback:function ($$v) {_vm.$set(_vm.menuItem.icon, _vm.landing.current_lang, $$v)},expression:"menuItem.icon[landing.current_lang]"}}),(_vm.showIcons)?_c('div',{staticStyle:{"width":"20vw","height":"250px","background":"#fff","border":"1px solid #ccc","position":"absolute","top":"42px","z-index":"100","display":"flex","flex-wrap":"wrap","overflow-y":"scroll","overflow-x":"hidden"}},_vm._l((_vm.icons.data.map(function (item) { return item.url; })),function(icon){return _c('div',{key:icon,staticStyle:{"height":"40px","width":"40px","padding":"5px"},style:(((_vm.menuItem.icon[_vm.landing.current_lang] === icon?'border:2px solid #007db5;background:#ddd;':'') + ";")),on:{"click":function($event){return _vm.handleChange(icon)}}},[_c('img',{staticStyle:{"height":"100%","width":"100%","cursor":"pointer"},attrs:{"src":icon,"alt":icon}})])}),0):_vm._e()],1):_vm._e(),_c('div',{staticClass:"control",staticStyle:{"display":"flex","align-items":"center"}},[_c('label',{staticClass:"label",staticStyle:{"width":"120px","display":"block","margin-bottom":"0"}},[_vm._v(_vm._s(_vm.$t('constructor["Название кнопки"]')))]),_c(VTextField,{ref:"name",style:(("max-width: " + (_vm.$store.state.isMobile?'auto':'300px'))),attrs:{"outlined":"","hide-details":"","solo":"","flat":"","dense":"","value":_vm.menuItem.text[_vm.currentLang],"rules":_vm.nameRules},on:{"input":_vm.handleChangeName}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }